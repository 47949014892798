<!--
 * @Author: fangjun
 * @Date: 2023-07-26 15:30:42
 * @LastEditors: fangjun
 * @Description: 
 * @LastEditTime: 2024-09-04 10:09:49
 * @FilePath: \src\App.vue
-->
<template>
  <el-config-provider>
    <router-view />
  </el-config-provider>
</template>

<script>
import { defineComponent, provide, watch, reactive, onBeforeUnmount, onMounted, computed } from 'vue'
import { ElConfigProvider, ElMessageBox } from 'element-plus'
import { getToken } from '@/utils/auth'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'app',
  components: {
    ElConfigProvider
  },
  setup() {
    const route = useRoute()
    const state = reactive({
      token: null,
      func: null,
      confirmOpened: false
    })

    const isWhitePage = computed(() => {
      return ['/login', '/auth-redirect', '/forgetPassword'].includes(route.path)
    })
    watch(
      () => state.token,
      (newV, oldV) => {
        console.log(newV, oldV)
        if (oldV && !isWhitePage.value && !state.confirmOpened) {
          state.confirmOpened = true
          ElMessageBox.alert('该账号已退出，点击确定按钮刷新页面', '提示', {
            confirmButtonText: '确定',
            showCancelButton: false,
            showClose: false,
            closeOnClickModal: false,
            closeOnPressEscape: false,
            type: 'warning',

            callback: (action) => {
              state.confirmOpened = false
              location.reload()
            }
          })
        }
      }
    )

    state.func = setInterval(() => {
      state.token = getToken()
    }, 1000)
    onBeforeUnmount(() => {
      clearInterval(state.func)
    })
  }
})
</script>
