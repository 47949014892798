<!--
 * @Author: fangjun
 * @Date: 2023-04-04 10:52:04
 * @LastEditors: fangjun
 * @Description: 
 * @LastEditTime: 2024-07-10 09:57:54
 * @FilePath: \src\konva\components\konvaPoint.vue
-->

<template>
  <div :class="{ isAmr }" @click="handleAmrClick">
    <div class="container" :style="containerStyle">
      <svg-icon class="arrow" :icon-class="isAmr? 'amr-arrow-' + svgIndex : 'station-arrow'" />
    </div>
    <div class="name-box" :style="textStyle" :title="element.attrs.name">
      <div class="name">{{ element.attrs.name }}</div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive, toRefs, getCurrentInstance, watch } from 'vue'
import { featureTypeOptions, useKonvaAppStore } from '@/store/konvaEditor/index.js'
export default {
  name: 'KonvaPoint',
  props: {
    propValue: {
      require: true
    },
    element: {
      type: Object
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const konvaApp = useKonvaAppStore()

    const state = reactive({
      func: null,
      svgIndex: 0
    })
    const { element } = toRefs(props)

    const attrs = computed(() => {
      return JSON.parse(JSON.stringify(element.value.attrs))
    })
    const typeName = computed(() => {
      return featureTypeOptions.find((type) => attrs.value.featureType === type.value)?.label ?? null
    })
    const defaultStyle = computed(() => {
      return featureTypeOptions.find((type) => attrs.value.featureType === type.value)?.style ?? {}
    })
    const textStyle = computed(() => {
      let { color, fontSize } = attrs.value
      let offsetTop = radius.value * 4
      return {
        color,
        'font-size': fontSize + 'px',
        top: 'calc(50% - ' + offsetTop + 'px)'
      }
    })

    const containerStyle = computed(() => {
      let rotate = 270 - attrs.value.yaw
      return {
        'font-size': radius.value * 5 + 'px',
        transform: 'rotate(' + rotate + 'deg)',
        opacity: element.value.style.opacity,
        color: attrs.value.fill
      }
    })
    const center = computed(() => {
      let { width, height } = element.value.style
      width = width ?? 2
      height = height ?? 2
      return {
        x: width / 2,
        y: height / 2
      }
    })
    const radius = computed(() => {
      return (4 * konvaApp.editor.canvasStyleData.scale) / 100
    })
    const isAmr = computed(() => {
      return element.value.type === 'amr'
    })
    watch(
      () => attrs.value,
      (newV, oldV) => {
        if (oldV.featureType !== newV.featureType) {
          //   state.konvaElement.stroke(defaultStyle.value.stroke)
          //   state.konvaElement.fill(defaultStyle.value.fill)
          element.value.attrs = { ...element.value.attrs, ...defaultStyle.value }
        }
        // state.konvaElement.strokeWidth(newV.strokeWidth)
        // state.konvaElement.stroke(newV.stroke)
        // state.konvaElement.fill(newV.fill)
        // state.konvaElement.radius(radius.value)
      },
      {
        deep: true
      }
    )
    watch(
      () => element.value.style,
      (value, old) => {
        let { width, height, opacity } = value
        let { oldWidth, oldHeight } = old

        if (!width || !height) {
        } else {
          if (width !== oldWidth || height !== oldHeight) {
            //缩放之后重新计算所有点的位置
            element.value.attrs.data = [center.value.x, center.value.y]
          }
        }
      },
      { deep: true }
    )
    const init = () => {
      element.value.attrs.data = [center.value.x, center.value.y]
      state.func && clearInterval(state.func)
      state.func = setInterval(() => {
        state.svgIndex++
        state.svgIndex = state.svgIndex > 3 ? 0 : state.svgIndex
      }, 400)
    }
    const handleAmrClick = () => {}
    onMounted(() => {
      init()
    })
    onUnmounted(() => {
      state.func && clearInterval(state.func)
    })
    return {
      ...toRefs(state),
      textStyle,
      containerStyle,
      typeName,
      isAmr,
      handleAmrClick
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
}
.isAmr:hover {
  cursor: default;
}
.name-box {
  position: absolute;
  // top: calc(50% - 15px);
  left: 50%;
  transform: translate((-50%, -50%));
  max-width: 100px;
  background-color: #ffffff80;
  border-radius: 5px;
  padding: 0 3px;
  .name {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.arrow {
  position: relative;
  top: 3px;
  left: 0;
}
</style>
