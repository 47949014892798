<!--
 * @Author: fangjun
 * @Date: 2023-07-10 15:43:54
 * @LastEditors: fangjun
 * @Description: 测距
 * @LastEditTime: 2023-07-20 11:14:19
 * @FilePath: \src\components\KonvaEditor\FunctionExtension\Range.vue
-->
<template>
  <div></div>
</template>
<script>
import Konva from 'konva'
import { useKonvaAppStore } from '@/store/konvaEditor/index'
import { STAGE_TOOL_TYPE_ENUM, STAGE_TOOL_TYPE_CONFIG } from '@/store/konvaEditor/tool'
import { reactive, toRefs, onBeforeUnmount, watch, computed, nextTick, onMounted } from 'vue'
import { apiGetMapResolution } from '@/api/mapMgt/mapQuery.js'
import closeImagePath from '@/icon/common/edit-close.svg'
export default {
  name: 'ExtensionRange',
  props: ['stage', 'mapData', 'originScale'],
  setup(props) {
    const { stage, mapData, originScale } = toRefs(props)
    const konvaApp = useKonvaAppStore()
    const state = reactive({
      pointMap: {},
      rangeLayer: null,
      curPos: null,
      curLine: null,
      curCircle: null,
      curRect: null,
      curText: null,
      originLine: null,
      resolution: 1,
      originCircle: null,
      originRect: null,
      originText: null,
      lastRect: null,
      lastText: null,
      originCloseImage: null,
      distanceMap: {}
    })
    state.originLine = new Konva.Line({
      name: 'line',
      points: [],
      stroke: 'red',
      strokeWidth: 1,
      lineCap: 'round'
    })
    state.originCircle = new Konva.Circle({
      name: 'circle',
      x: 0,
      y: 0,
      radius: 3,
      fill: '#fff',
      stroke: 'red',
      strokeWidth: 1
    })
    state.originRect = new Konva.Rect({
      name: 'rect',
      x: 0,
      y: 0,
      width: 80,
      height: 20,
      stroke: '#000',
      strokeWidth: 1,
      fill: '#fff'
    })
    state.originText = new Konva.Text({
      name: 'text',
      x: 0,
      y: 0,
      text: '',
      fontSize: 14,
      width: 80,
      height: 20,
      fill: '#000',
      align: 'center',
      verticalAlign: 'middle'
    })
    Konva.Image.fromURL(closeImagePath, (imageNode) => {
      state.originCloseImage = imageNode
      imageNode.setAttrs({
        name: 'closeImage',
        x: 0,
        y: 0,
        width: 15,
        height: 15,
        fill: '#ffffff80'
      })
    })

    state.lastRect = state.originRect.clone()
    state.lastRect.name('lastRect')
    state.lastText = state.originText.clone()
    state.lastText.name('lastText')

    state.lastRect.hide()
    state.lastText.hide()
    state.rangeLayer = new Konva.Layer()
    state.rangeLayer.add(state.lastRect)
    state.rangeLayer.add(state.lastText)

    state.rangeLayer.batchDraw()
    const showPoints = computed(() => {
      if (state.curLine) {
        if (state.curPos) {
          let { offsetX, offsetY } = state.curPos
          return [...state.pointMap[state.curLine._id], offsetX, offsetY]
        } else {
          return state.pointMap[state.curLine._id]
        }
      } else return []
    })
    const editorScale = computed(() => {
      return konvaApp.editor.canvasStyleData.scale / 100
    })
    const nodesLength = computed(() => {
      return state.rangeLayer.getChildren()?.length
    })
    const allLines = computed(() => {
      return state.rangeLayer.getChildren((node) => node.attrs.name && node.attrs.name === 'line')
    })
    const allCloseImage = computed(() => {
      return state.rangeLayer.getChildren((node) => node.attrs.name && node.attrs.name === 'closeImage')
    })
    const allCircles = computed(() => {
      return state.rangeLayer.getChildren((node) => node.attrs.name && node.attrs.name === 'circle')
    })
    const allRects = computed(() => {
      return state.rangeLayer.getChildren((node) => node.attrs.name && node.attrs.name === 'rect')
    })
    const allTexts = computed(() => {
      return state.rangeLayer.getChildren((node) => node.attrs.name && node.attrs.name === 'text')
    })
    const isRanging = computed(() => {
      return konvaApp.tool.isRanging
    })
    const isCtrlDown = computed(() => {
      return konvaApp.editor.isCtrlDown
    })
    const showTotalDistance = computed(() => {
      let pointArray = state.pointMap[state.curLine._id]
      let pointNum = pointArray.length
      let distance
      if (pointNum >= 2) {
        let lastPoint = {
          x: pointArray[pointNum - 2],
          y: pointArray[pointNum - 1]
        }
        let currentPoint = {
          x: state.curPos.offsetX,
          y: state.curPos.offsetY
        }

        distance = Math.sqrt(
          Math.pow(Math.abs(lastPoint.x - currentPoint.x), 2) + Math.pow(Math.abs(lastPoint.y - currentPoint.y), 2)
        )

        distance = (distance / editorScale.value) * state.resolution
      }
      let result = (state.distanceMap[state.curLine._id] + distance).toFixed(2)
      return result
    })
    watch(
      () => editorScale.value,
      (newV, oldV) => {
        for (let id in state.pointMap) {
          state.pointMap[id] = state.pointMap[id].map((point) => {
            return originScale.value * newV * point
          })
        }
      }
    )
    watch(
      () => state.pointMap,
      (newV, oldV) => {
        allLines.value.forEach((node) => {
          // node.zIndex(nodesLength.value - 1)

          if (newV[node._id] && Array.isArray(newV[node._id])) {
            node.points(newV[node._id])
          }
        })
        let indexItem = 0
        for (let id in newV) {
          let points = newV[id]
          let length = points.length
          for (let index = 0; index < length - 1; index += 2) {
            updateItem(indexItem, points[index], points[index + 1])

            indexItem++
          }
          if (state.curLine && state.curLine._id == id) return
          updateCloseImage(id, points[length - 2], points[length - 1])
        }
      },
      { deep: true }
    )
    watch(
      () => showPoints.value,
      (newV, oldV) => {
        state.curLine?.points(newV)
      },
      { deep: true }
    )
    watch(
      () => isRanging.value,
      (newV) => {
        if (!newV) {
          reset()
        }
      }
    )
    watch(
      () => state.curPos,
      (newV) => {
        if (newV) {
          if (showPoints.value.length >= 4) {
            !state.lastRect.isVisible() && state.lastRect.show()
            !state.lastText.isVisible() && state.lastText.show()
            let distanceStr = showTotalDistance.value.toString()
            let width = distanceStr.length * 10 + 60
            updateLastRect(newV.offsetX, newV.offsetY, width)
            updateLastText('总长：' + distanceStr + 'm', newV.offsetX, newV.offsetY)
          }
        } else {
          state.lastRect.isVisible() && state.lastRect.hide()
          state.lastText.isVisible() && state.lastText.hide()
        }
      },
      {
        deep: true
      }
    )
    const reset = () => {
      state.pointMap = {}
      state.curPos = null
      state.curLine = null
      state.curCircle = null
      state.curRect = null
      state.curText = null
      state.rangeLayer.removeChildren()
    }
    const getMapResolution = () => {
      apiGetMapResolution({
        mapId: mapData.value.mapId
      })
        .then((result) => {
          state.resolution = result.data.resolution
        })
        .catch((err) => {})
    }
    const addLine = () => {
      if (state.curLine) return
      state.curLine = state.originLine.clone()
      state.curLine.attrs.targetLineId = state.curLine._id
      state.rangeLayer.add(state.curLine)
      state.pointMap[state.curLine._id] = []
      state.distanceMap[state.curLine._id] = 0
    }
    const addCircle = (x, y) => {
      if (!state.curLine) return
      state.curCircle = state.originCircle.clone()
      state.curCircle.attrs.targetLineId = state.curLine._id
      state.curCircle.x(x)
      state.curCircle.y(y)
      state.rangeLayer.add(state.curCircle)
    }
    const addRect = (x, y, width = null) => {
      if (!state.curLine) return
      state.curRect = state.originRect.clone()
      state.curRect.attrs.targetLineId = state.curLine._id

      width && state.curRect.width(width)
      let pos = calcPosition(
        x + 0.1 * state.curRect.width(),
        y - 0.5 * state.curRect.height(),
        state.curRect.width(),
        state.curRect.height()
      )
      state.curRect.x(pos.x)
      state.curRect.y(pos.y)
      state.rangeLayer.add(state.curRect)
    }
    const addText = (x, y, content = '起点', width = null) => {
      if (!state.curLine) return
      state.curText = state.originText.clone()
      state.curText.attrs.targetLineId = state.curLine._id
      width && state.curText.width(width)
      let pos = calcPosition(
        x + 0.1 * state.curText.width(),
        y - 0.5 * state.curText.height(),
        state.curText.width(),
        state.curText.height()
      )
      state.curText.x(pos.x)
      state.curText.y(pos.y)
      state.curText.text(content)
      state.rangeLayer.add(state.curText)
    }
    const addCloseImage = () => {
      if (!state.curLine) return
      let pointArray = state.pointMap[state.curLine._id]
      let pointNum = pointArray.length
      if (pointNum < 4) {
        return true
      }

      let x = pointArray[pointNum - 2],
        y = pointArray[pointNum - 1]
      let closeImage = state.originCloseImage.clone()
      closeImage.attrs.targetLineId = state.curLine._id
      closeImage.on('click', handleClickCloseImage)
      closeImage.on('mouseenter', function () {
        stage.value.container().style.cursor = 'pointer'
      })

      closeImage.on('mouseleave', function () {
        stage.value.container().style.cursor = ''
      })
      let pos = calcPosition(
        x - 1.5 * closeImage.width(),
        y - 0.5 * closeImage.height(),
        closeImage.width(),
        closeImage.height(),
        0.5 * closeImage.width(),
        0.5 * closeImage.height()
      )

      closeImage.x(pos.x)
      closeImage.y(pos.y)
      checkCloseImagePosition(closeImage)
      // state.curCircle.hide()
      state.rangeLayer.add(closeImage)
    }
    const updateCircle = (index, x, y) => {
      let circle = allCircles.value[index]
      if (circle) {
        circle.x(x)
        circle.y(y)
      }
    }
    const updateRect = (index, x, y) => {
      let rect = allRects.value[index]
      if (rect) {
        let pos = calcPosition(x + 0.1 * rect.width(), y - 0.5 * rect.height(), rect.width(), rect.height())
        rect.x(pos.x)
        rect.y(pos.y)
      }
    }
    const updateLastRect = (x, y, width = null) => {
      let rect = state.lastRect
      width && rect.width(width)
      let pos = calcPosition(x + 0.1 * rect.width(), y - 0.5 * rect.height(), rect.width(), rect.height())
      rect.x(pos.x)
      rect.y(pos.y)
    }
    const updateText = (index, x, y) => {
      let text = allTexts.value[index]
      if (text) {
        let pos = calcPosition(x + 0.1 * text.width(), y - 0.5 * text.height(), text.width(), text.height())
        text.x(pos.x)
        text.y(pos.y)
      }
    }
    const updateLastText = (content, x, y) => {
      let text = state.lastText
      let width = content.length * 10 + 20
      text.width(width)
      let pos = calcPosition(x + 0.1 * text.width(), y - 0.5 * text.height(), text.width(), text.height())
      text.x(pos.x)
      text.y(pos.y)
      state.lastText.text(content)
    }
    const updateItem = (index, x, y) => {
      updateCircle(index, x, y)
      updateRect(index, x, y)
      updateText(index, x, y)
    }
    const updateCloseImage = (id, x, y) => {
      let closeImage = null
      try {
        closeImage = allCloseImage.value.find((node) => node.attrs.targetLineId == id)
      } catch (error) {}

      if (!closeImage) return
      let pos = calcPosition(
        x - 1.5 * closeImage.width(),
        y - 0.5 * closeImage.height(),
        closeImage.width(),
        closeImage.height(),
        0.5 * closeImage.width(),
        0.5 * closeImage.height()
      )

      closeImage.x(pos.x)
      closeImage.y(pos.y)
      checkCloseImagePosition(closeImage)
    }
    /**
     * @description: 坐标计算 防止溢出画布
     * @param {*} x
     * @param {*} y
     * @param {*} width
     * @param {*} height
     * @return {*}
     */
    const calcPosition = (x, y, width, height, offsetX = 5, offsetY = 5) => {
      let stageStyle = konvaApp.editor.stageComponentData.style
      let minY = 0,
        maxY = minY + stageStyle.height,
        minX = 0,
        maxX = minX + stageStyle.width

      if (x + width > maxX) {
        x = maxX - width - offsetX
      }
      if (x - width < minX) {
        x = offsetX
      }

      if (y + height > maxY) {
        y = maxY - height - offsetY
      }
      if (y - height < minY) {
        y = offsetY
      }
      return {
        x,
        y
      }
    }
    const checkCloseImagePosition = (closeImage) => {
      let rectArray = allRects.value.filter((rect) => (rect.attrs.targetLineId === closeImage.attrs.targetLineId))
      let rect = rectArray[rectArray.length - 1]
      if (!rect) return
      let stageStyle = konvaApp.editor.stageComponentData.style

      let rectPost = {
        x: rect.x(),
        y: rect.y()
      }
      if (
        closeImage.x() + 0.5 * closeImage.width() > rectPost.x ||
        closeImage.x() - 0.5 * closeImage.width() < rectPost.x + rect.width()
      ) {
        if (closeImage.y() > 0.5 * stageStyle.height) {
          closeImage.y(closeImage.y() - 1.5 * closeImage.height())
        } else {
          closeImage.y(closeImage.y() + 1.5 * closeImage.height())
        }
      }
    }
    const overLine = () => {
      if (!state.curLine) return
      if (state.pointMap[state.curLine._id].length < 4) {
        delete state.pointMap[state.curLine._id]
        state.curLine.destroy()
        state.curCircle.destroy()
        state.curRect.destroy()
        state.curText.destroy()
        state.curPos = null
        state.curLine = null
        state.curCircle = null
        state.curRect = null
        state.curText = null
      } else {
        state.curPos = null

        nextTick(() => {
          setLastItem()
          addCloseImage()
          state.curLine = null
          state.curCircle = null
          state.curRect = null
          state.curText = null
        })
      }
    }
    const setLastItem = () => {
      state.curRect.width(state.curRect.width() + 60)
      let pos = calcPosition(
        state.curRect.x() + 0.1 * 60,
        state.curRect.y(),
        state.curRect.width(),
        state.curRect.height()
      )
      state.curRect.x(pos.x)
      state.curRect.y(pos.y)
      state.curText.width(state.curText.width() + 60)
      state.curText.text('总长：' + state.curText.text())
      pos = calcPosition(state.curText.x() + 0.1 * 60, state.curText.y(), state.curText.width(), state.curText.height())
      state.curText.x(pos.x)
      state.curText.y(pos.y)
    }
    /**
     * @description: 检测要添加的点和上个点是不是很接近
     * @param {*} x x坐标
     * @param {*} y y坐标
     * @return {*}
     */
    const checkLastPoint = (x, y) => {
      let pointArray = state.pointMap[state.curLine._id]
      let pointNum = pointArray.length
      if (pointNum < 2) {
        return true
      }
      // 偏差
      let deviation = 2
      let lastPoint = {
        x: pointArray[pointNum - 2],
        y: pointArray[pointNum - 1]
      }
      // console.log(lastPoint.x - x, lastPoint.y - y)
      if (Math.abs(lastPoint.x - x) < deviation || Math.abs(lastPoint.y - y) < deviation) {
        return false
      } else {
        return true
      }
    }
    const handleStageClick = (env) => {
      if (!isRanging.value || isCtrlDown.value) return
      let { offsetX, offsetY } = env
      // 0 左击 1 滚轮 2 右击
      if (env.button === 0) {
        addLine()
        if (!checkLastPoint(offsetX, offsetY)) return
        state.pointMap[state.curLine._id].push(offsetX)
        state.pointMap[state.curLine._id].push(offsetY)

        let pointArray = state.pointMap[state.curLine._id]
        let pointNum = pointArray.length
        let content = '起点'
        let width = 40

        if (pointNum >= 4) {
          let currentPoint = {
            x: pointArray[pointNum - 2],
            y: pointArray[pointNum - 1]
          }
          let lastPoint = {
            x: pointArray[pointNum - 4],
            y: pointArray[pointNum - 3]
          }

          let distance = Math.sqrt(
            Math.pow(Math.abs(lastPoint.x - currentPoint.x), 2) + Math.pow(Math.abs(lastPoint.y - currentPoint.y), 2)
          )
          distance = (distance / editorScale.value) * state.resolution
          state.distanceMap[state.curLine._id] += distance
          content = state.distanceMap[state.curLine._id].toFixed(2) + 'm' //* state.resolution
          width = content.length * 10
        }
        addCircle(offsetX, offsetY)
        addRect(offsetX, offsetY, width)
        addText(offsetX, offsetY, content, width)
      } else if (env.button === 2) {
        overLine()
      }
    }
    const handleStageMousemove = (env) => {
      if (!isRanging.value || isCtrlDown.value) return

      let { offsetX, offsetY } = env
      state.curPos = { offsetX, offsetY }
    }
    const handleClickCloseImage = (env) => {
      env.cancelBubble = true
      let destroyChildren = state.rangeLayer.getChildren(
        (node) => node.attrs.targetLineId && node.attrs.targetLineId === env.currentTarget.attrs.targetLineId
      )
      destroyChildren.forEach((children) => {
        children.destroy()
      })
      delete state.pointMap[env.currentTarget.attrs.targetLineId]
      stage.value.container().style.cursor = ''
    }

    const addEvent = () => {
      stage.value.addEventListener('click', handleStageClick)
      stage.value.addEventListener('mousemove', handleStageMousemove)
    }
    const removeEvent = () => {
      stage.value.removeEventListener('click', handleStageClick)
      stage.value.removeEventListener('mousemove', handleStageMousemove)
    }
    onMounted(() => {
      stage.value.add(state.rangeLayer)
    })
    addEvent()
    getMapResolution()
    onBeforeUnmount(() => {
      removeEvent()
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped></style>
