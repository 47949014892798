import { debounce } from '@/utils/index'
export default {
  mounted(el, binding) {
    let [fn, delay = 300, event = 'click', immediate = false] = binding.value
    el.addEventListener(event, debounce(fn, delay, immediate))
  },
  beforeUnmount(el, binding) {
    let [fn, delay = 300, event = 'click', immediate = false] = binding.value
    el.removeEventListener(event, debounce(fn, delay, immediate))
  }
}
