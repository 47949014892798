<!--
 * @Author: fangjun
 * @Date: 2023-03-07 11:22:30
 * @LastEditors: fangjun
 * @Description: 
 * @LastEditTime: 2023-03-10 16:37:33
 * @FilePath: \src\konva\components\Group.vue
-->
<template>
  <div class="group">
    <template v-for="item in propValue" :key="item.id">
      <component
        class="component"
        :is="item.component"
        :style="item.groupStyle"
        :propValue="item.propValue"
        :id="'component' + item.id"
        :element="item"
      />
    </template>
  </div>
</template>

<script>
import { toRefs, watch } from 'vue'
import { useEditorStore } from '@/store/konvaEditor/editor'
import { useComposeStore } from '@/store/konvaEditor/compose'
import { $ } from '@/utils/konvaEditor/utils'
import { mod360 } from '@/utils/konvaEditor/translate'
export default {
  name: 'Group',
  props: {
    propValue: {
      type: Array,
      default: () => []
    },
    element: {
      type: Object
    }
  },
  setup(props) {
    const { propValue, element } = toRefs(props)
    const editorApp = useEditorStore()
    const composeApp = useComposeStore()
    
    const calcChidrenStyle = (component, editorRect, parentStyle) => {
      const componentRect = $(`#component${component.id}`).getBoundingClientRect()
      // 获取元素的中心点坐标
      const center = {
        x: componentRect.left - editorRect.left + componentRect.width / 2,
        y: componentRect.top - editorRect.top + componentRect.height / 2
      }
      component.style.rotate = mod360(component.style.rotate + parentStyle.rotate)
      component.style.width = (parseFloat(component.groupStyle.width) / 100) * parentStyle.width
      component.style.height = (parseFloat(component.groupStyle.height) / 100) * parentStyle.height
      // 计算出元素新的 top left 坐标
      component.style.left = center.x - component.style.width / 2
      component.style.top = center.y - component.style.height / 2
    }
    const updateChildrenStyle = () => {
      const parentStyle = { ...editorApp.curComponent.style }
      const components = propValue.value
      const editorRect = composeApp.editor.getBoundingClientRect()
      components.forEach((component) => {
        calcChidrenStyle(component, editorRect, parentStyle)
      })
      // 将组合中的各个子组件拆分出来，并计算它们新的 style
    }
    watch(
      () => element.value.style,
      () => {
        updateChildrenStyle()
      },
      { deep: true }
    )
  }
}
</script>

<style lang="scss" scoped>
.group {
  position: relative;
  width: 100%;
  height: 100%;
  // & > div {

  // }
  .component {
    position: absolute;
  }
}
</style>
