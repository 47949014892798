<!--
 * @Author: fangjun
 * @Date: 2023-03-08 16:00:33
 * @LastEditors: fangjun
 * @Description: 
 * @LastEditTime: 2024-07-11 14:56:38
 * @FilePath: \src\konva\components\konvaStage.vue
-->
<template>
  <div>
    <div @contextmenu="handleOperationOver" id="konvaStage" v-loading="loading"></div>
    <ExtensionRange v-if="extensionVisible.range" :stage="konvaStage" :mapData="mapData" :originScale="originScale" />
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, watch, computed, nextTick, onUnmounted, onBeforeUnmount } from 'vue'
import Konva from 'konva'
import {
  useKonvaAppStore,
  STAGE_OPERATION_TYPE_ENUM,
  FEATURE_TYPE_ENUM,
  featureTypeOptions
} from '@/store/konvaEditor/index'
import { MIN_RECT } from '@/utils/konvaEditor/style'
import { emitter } from '@/utils/mitt'
import { deepCopy, base64ToImg, downloadURI } from '@/utils/konvaEditor/utils'
import { customComponentData, pointComponentData } from '@/konva/components/component-list'
import generateID from '@/utils/konvaEditor/generateID'
import { ElMessage } from 'element-plus'
import { apiGetMapInfo } from '@/api/mapMgt/mapQuery'
import { useRoute } from 'vue-router'
import ExtensionRange from '@/components/KonvaEditor/FunctionExtension/Range.vue'
export default {
  props: {
    propValue: {
      type: String,
      require: true
    },
    element: {
      type: Object
    }
  },
  components: { ExtensionRange },
  setup(props) {
    const { propValue, element } = toRefs(props)
    const konvaApp = useKonvaAppStore()
    const route = useRoute()

    const state = reactive({
      loading: false,
      konvaImage: null,
      konvaLine: null,
      konvaCircle: null,
      konvaLayer: null,
      konvaStage: null,
      originScale: 1,
      eraserLine: null,
      obstacleLine: null,
      newComponentOpacity: 1,
      mapData: null,
      showNormalComponentId: [],
      showNormal: true,
      originImage: null,
      imageLoaded: false, //火狐浏览器会多次加载onload事件
      extensionVisible: {
        range: false
      }
    })
    const editorScale = computed(() => {
      return konvaApp.editor.canvasStyleData.scale / 100
    })
    const style = computed(() => {
      let { top, left, width, height } = element.value.style
      return { top, left, width, height }
    })
    const attrs = computed(() => {
      return element.value.attrs
    })
    const points = computed(() => {
      if (!isCurPosEmpty.value) {
        let curPos = attrs.value.curPos
        return [...attrs.value.data, curPos.offsetX, curPos.offsetY]
      } else {
        return [...attrs.value.data]
      }
    })
    const obstaclePoints = computed(() => {
      if (drawMode.value === STAGE_OPERATION_TYPE_ENUM.DRAW_OBSTACLE) {
        if (!isCurPosEmpty.value) {
          let curPos = attrs.value.curPos
          return [...attrs.value.obstaclePoints, curPos.offsetX, curPos.offsetY]
        } else {
          return [...attrs.value.obstaclePoints]
        }
      } else {
        return [...attrs.value.obstaclePoints]
      }
    })
    const isCurPosEmpty = computed(() => {
      return (
        attrs.value.curPos === null || attrs.value.curPos === undefined || Object.keys(attrs.value.curPos).length === 0
      )
    })
    const normalComponentData = computed(() => {
      return konvaApp.editor.componentData.filter((component) => component.type !== 'stage')
    })
    const drawMode = computed(() => {
      return konvaApp.editor.drawMode
    })
    const eraserLineList = computed(() => {
      let result =
        state.konvaLayer.getChildren((node) => {
          return node.attrs.name && node.attrs.name === 'eraser'
        }) ?? []
      return result
    })
    const id = computed(() => {
      return route.query.id
    })
    //是否绘制底图
    const isDrawMap = computed(() => {
      return [
        STAGE_OPERATION_TYPE_ENUM.DRAW_OBSTACLE,
        STAGE_OPERATION_TYPE_ENUM.CLEAN_OBSTACLE,
        STAGE_OPERATION_TYPE_ENUM.ERASER
      ].includes(drawMode.value)
    })
    const isCtrlDown = computed(() => {
      return konvaApp.editor.isCtrlDown
    })
    watch(
      () => style.value,
      (newV, oldV) => {
        let { top, left, width, height, opacity } = newV
        console.log('update stage')
        state.konvaStage?.width(width)
        state.konvaStage?.height(height)
        state.konvaStage?.opacity(opacity)
        state.konvaImage?.width(width)
        state.konvaImage?.height(height)
        if ((oldV.top && top !== oldV.top) || (oldV.top && left !== oldV.top)) {
          let offsetX = left - oldV.left,
            offsetY = top - oldV.top
          normalComponentData.value.forEach((component) => {
            component.style.top = offsetY + component.style.top
            component.style.left = offsetX + component.style.left
          })
        }
      },
      { deep: true }
    )
    watch(
      () => attrs.value,
      (newV, oldV) => {
        state.konvaLine?.strokeWidth(newV.strokeWidth)
        state.konvaLine?.stroke(newV.stroke)
        state.konvaLine?.tension(newV.tension)
        state.konvaLine?.fill(newV.fill)
        state.obstacleLine?.strokeWidth(newV.eraserStrokeWidth)
        eraserLineList.value.forEach((node) => {
          if (newV.eraserLineIdList.includes(node._id)) {
            node.show()
          } else {
            node.hide()
          }
        })
        if (isDrawMap.value) {
          state.konvaCircle?.radius(newV.eraserStrokeWidth / 2)
        } else {
          state.konvaCircle?.radius(newV.strokeWidth / 2)
        }
        if (newV.imageData !== oldV.imageData) {
          reloadImage()
        }
      },
      { deep: true }
    )
    watch(
      () => points.value,
      (value) => {
        if (drawMode.value !== STAGE_OPERATION_TYPE_ENUM.DRAW_OBSTACLE) {
          state.konvaLine?.points(value)
        } else {
          state.konvaLine?.points([])
        }
      },
      { deep: true }
    )
    watch(
      () => obstaclePoints.value,
      (value) => {
        state.obstacleLine?.points(value)
      },
      { deep: true }
    )
    watch(
      () => drawMode.value,
      (value, oldV) => {
        attrs.value.curPos = null
        attrs.value.data = []
        attrs.value.isPaint = true
        state.newComponentOpacity = 1
        state.konvaCircle.show()
        state.konvaCircle.radius(attrs.value.strokeWidth / 2)
        switch (value) {
          case STAGE_OPERATION_TYPE_ENUM.DRAW_BROKEN_LINE:
            state.konvaLine = new Konva.Line({
              points: attrs.value.data,
              stroke: attrs.value.stroke,
              strokeWidth: attrs.value.strokeWidth
            })
            state.konvaLayer.add(state.konvaLine)
            state.konvaLine?.closed(false)
            break
          case STAGE_OPERATION_TYPE_ENUM.DRAW_POLYGON:
            state.konvaLine = new Konva.Line({
              points: attrs.value.data,
              stroke: attrs.value.stroke,
              strokeWidth: attrs.value.strokeWidth
            })
            state.konvaLayer.add(state.konvaLine)
            state.newComponentOpacity = 0.4
            state.konvaLine?.closed(true)
            break
          case STAGE_OPERATION_TYPE_ENUM.ERASER:
            attrs.value.eraserStroke = '#808080'
            attrs.value.eraserGlobalCompositeOperation = 'source-over'
            attrs.value.isPaint = false
            state.konvaCircle.radius(attrs.value.eraserStrokeWidth / 2)

            hideNormalComponent()
            break
          case STAGE_OPERATION_TYPE_ENUM.DRAW_OBSTACLE:
            attrs.value.eraserStroke = '#000'
            attrs.value.eraserGlobalCompositeOperation = 'source-over'
            state.konvaCircle.radius(attrs.value.eraserStrokeWidth / 2)
            state.obstacleLine = null
            attrs.value.obstaclePoints = []
            hideNormalComponent()
            break
          case STAGE_OPERATION_TYPE_ENUM.CLEAN_OBSTACLE:
            attrs.value.eraserStroke = '#fff'
            attrs.value.eraserGlobalCompositeOperation = 'source-over'
            attrs.value.isPaint = false
            state.konvaCircle.radius(attrs.value.eraserStrokeWidth / 2)

            hideNormalComponent()

            break

          case STAGE_OPERATION_TYPE_ENUM.ADD_POINT:
            state.konvaCircle.hide()

            break
          case STAGE_OPERATION_TYPE_ENUM.CLOSED:
            attrs.value.curPos = null
            attrs.value.isPaint = false
            attrs.value.data = []
            state.konvaCircle.hide()
            state.konvaLine?.destroy()

            break
        }
        state.konvaLine?.opacity(state.newComponentOpacity)
        if (
          [
            STAGE_OPERATION_TYPE_ENUM.DRAW_OBSTACLE,
            STAGE_OPERATION_TYPE_ENUM.CLEAN_OBSTACLE,
            STAGE_OPERATION_TYPE_ENUM.ERASER
          ].includes(oldV) &&
          !isDrawMap.value
        ) {
          showNormalComponent()
        }
      }
    )
    watch(
      () => editorScale.value,
      (newV, oldV) => {
        element.value.attrs.data = element.value.attrs.data.map((point) => {
          return state.originScale * newV * point
        })
        element.value.attrs.obstaclePoints = element.value.attrs.obstaclePoints.map((point) => {
          return state.originScale * newV * point
        })
        eraserLineList.value.forEach((node) => {
          let points = node.points().map((point) => {
            return state.originScale * newV * point
          })
          node.points(points)
        })
        state.originScale = 1 / newV
      }
    )
    //缩放时调整画布坐标偏移，使其居中放大效果
    const calcStageOffsetForScale = ({ newScale, oldScale }) => {
      console.log(newScale, oldScale)
      if (oldScale != 0) {
        let intervalScale = newScale - oldScale
        element.value.style.top -= (intervalScale * element.value.style.height) / 2
        element.value.style.left -= (intervalScale * element.value.style.width) / 2
      }
    }
    const getDefaultStyle = (featureType) => {
      return featureTypeOptions.find((type) => featureType === type.value)?.style ?? {}
    }
    const getMapInfo = () => {
      let params = { id: id.value }
      state.loading = true
      apiGetMapInfo(params)
        .then((result) => {
          if (result.code === 200) {
            state.mapData = result.data
            konvaApp.stage.setMapData(state.mapData)

            createStage()
          }
        })
        .catch((err) => {})
    }
    const createStage = () => {
      let style = element.value.style
      let image = new Image()
      image.src = state.mapData.mapPngUrl
      image.crossOrigin = 'Anonymous'
      image.onload = () => {
        if (state.imageLoaded) return
        state.originImage = image
        let { width, height } = image
        //计算缩放
        konvaApp.editor.calcLimitScale({ width, height })
        if (style.top === null || style.left === null) {
          let result = calcStagePositionAndSize(width, height)
          style.left = result.left
          style.top = result.top
          style.width = result.width
          style.height = result.height
          konvaApp.editor.setCanvasStyle({
            ...konvaApp.editor.canvasStyleData.value,
            scale: result.scale * 100
          })
          emitter.emit('initScaleModel')
        } else {
          style.height = height * editorScale.value
          style.width = width * editorScale.value
        }

        state.konvaStage = new Konva.Stage({
          container: 'konvaStage',
          width: style.width,
          height: style.height
        })
        konvaApp.stage.setStage(state.konvaStage)
        state.konvaImage = new Konva.Image({
          x: 0,
          y: 0,
          image: image,
          width: style.width,
          height: style.height,
          fill: '#808080'
        })

        state.konvaCircle = new Konva.Circle({
          x: 0,
          y: 0,
          radius: attrs.value.strokeWidth / 2,
          stroke: '#000',
          strokeWidth: 1
        })

        state.konvaLayer = new Konva.Layer()
        state.originScale = 1 / editorScale.value

        state.konvaLayer.add(state.konvaImage)

        state.konvaLayer.batchDraw()
        state.konvaStage.add(state.konvaLayer)

        let tempLayer = new Konva.Layer()
        tempLayer.add(state.konvaCircle)
        tempLayer.batchDraw()
        state.konvaStage.add(tempLayer)
        addEvent()
        state.loading = false
        emitter.emit('getEditMapResult')
        state.imageLoaded = true

        state.extensionVisible.range = true
      }
    }
    const overOperation = (showMessage = false) => {
      switch (drawMode.value) {
        case STAGE_OPERATION_TYPE_ENUM.DRAW_BROKEN_LINE:
          if (attrs.value.isPaint) {
            saveDraw(showMessage)
          }
          break
        case STAGE_OPERATION_TYPE_ENUM.DRAW_POLYGON:
          if (attrs.value.isPaint) {
            saveDraw(showMessage)
          }
          break
        case STAGE_OPERATION_TYPE_ENUM.DRAW_OBSTACLE:
          if (attrs.value.isPaint) {
            saveDraw(showMessage)
          }
          break
        case STAGE_OPERATION_TYPE_ENUM.ERASER:
          saveDraw(showMessage)
          break
        case STAGE_OPERATION_TYPE_ENUM.CLEAN_OBSTACLE:
          saveDraw(showMessage)
          break
        case STAGE_OPERATION_TYPE_ENUM.ADD_POINT:
          konvaApp.editor.closeDrawMode()
          break
      }
    }
    const handleOperationOver = (e) => {
      if (!konvaApp.editor.isDrawing || isCtrlDown.value) return
      e.preventDefault()
      e.stopPropagation()
      overOperation()
    }
    const handleStageClick = (e) => {
      if (!konvaApp.editor.isDrawing || isCtrlDown.value) return
      let { offsetX, offsetY } = e.evt
      switch (drawMode.value) {
        case STAGE_OPERATION_TYPE_ENUM.DRAW_BROKEN_LINE:
          // 0 左击 1 滚轮 2 右击
          if (attrs.value.isPaint) {
            if (e.evt.button === 0) {
              attrs.value.data.push(offsetX)
              attrs.value.data.push(offsetY)
              konvaApp.snapshot.recordSnapshot()
            }
          }

          break
        case STAGE_OPERATION_TYPE_ENUM.DRAW_POLYGON:
          if (attrs.value.isPaint) {
            if (e.evt.button === 0) {
              attrs.value.data.push(offsetX)
              attrs.value.data.push(offsetY)
              konvaApp.snapshot.recordSnapshot()
            }
          }

          break
        case STAGE_OPERATION_TYPE_ENUM.DRAW_OBSTACLE:
          // 0 左击 1 滚轮 2 右击
          if (attrs.value.isPaint) {
            if (e.evt.button === 0) {
              attrs.value.obstaclePoints.push(offsetX)
              attrs.value.obstaclePoints.push(offsetY)
              state.obstacleLine = new Konva.Line({
                name: 'eraser',
                stroke: attrs.value.eraserStroke,
                strokeWidth: attrs.value.eraserStrokeWidth,
                globalCompositeOperation: attrs.value.eraserGlobalCompositeOperation,
                points: attrs.value.obstaclePoints,
                lineJoin: 'round'
              })
              attrs.value.eraserLineIdList.push(state.obstacleLine._id)
              state.konvaLayer.add(state.obstacleLine)
              konvaApp.snapshot.recordSnapshot()
            }
          }

          break
        case STAGE_OPERATION_TYPE_ENUM.ERASER:
          break

        case STAGE_OPERATION_TYPE_ENUM.ADD_POINT:
          if (e.evt.button === 0) {
            let copyPointData = deepCopy(pointComponentData)
            copyPointData.id = generateID()
            copyPointData.attrs.name = '点位-' + copyPointData.id.substr(0, 6)
            copyPointData.propValue = ''
            copyPointData.attrs.featureType = FEATURE_TYPE_ENUM.NORMAL_POINT
            let defaultStyle = getDefaultStyle(copyPointData.attrs.featureType)

            copyPointData.attrs.data = [offsetX + element.value.style.left, offsetY + element.value.style.top]
            let newComponentData = calcComponentPositionAndSizeForPoint(copyPointData.attrs.data, 1)
            copyPointData.style = { ...copyPointData.style, ...newComponentData.style }
            copyPointData.attrs = { ...copyPointData.attrs, ...newComponentData.attrs, ...defaultStyle }
            konvaApp.editor.addComponent({ component: copyPointData })

            ElMessage({ message: '添加点位成功', type: 'success' })
            konvaApp.editor.closeDrawMode()
            konvaApp.snapshot.recordSnapshot()
            nextTick(() => {
              let index = konvaApp.editor.getComponentIndex(copyPointData)
              konvaApp.editor.setClickComponentStatus(true)
              konvaApp.editor.setCurComponent({
                component: konvaApp.editor.componentData[index],
                index
              })
              konvaApp.editor.toggleShowAttrListPanel(true)
            })
          }

          break
      }
    }

    const handleStageMousemove = (e) => {
      if (!konvaApp.editor.isDrawing || isCtrlDown.value) return
      let { offsetX, offsetY } = e.evt
      state.konvaCircle.x(offsetX)
      state.konvaCircle.y(offsetY)
      switch (drawMode.value) {
        case STAGE_OPERATION_TYPE_ENUM.DRAW_BROKEN_LINE:
          if (attrs.value.isPaint) attrs.value.curPos = { offsetX, offsetY }
          break
        case STAGE_OPERATION_TYPE_ENUM.DRAW_POLYGON:
          if (attrs.value.isPaint) attrs.value.curPos = { offsetX, offsetY }
          break
        case STAGE_OPERATION_TYPE_ENUM.DRAW_OBSTACLE:
          if (attrs.value.isPaint) attrs.value.curPos = { offsetX, offsetY }

          break
        case STAGE_OPERATION_TYPE_ENUM.CLEAN_OBSTACLE:
          if (attrs.value.isPaint) {
            var newPoints = state.eraserLine.points().concat([offsetX, offsetY])
            state.eraserLine.points(newPoints)
            state.konvaLayer.batchDraw()
          }
          break
        case STAGE_OPERATION_TYPE_ENUM.ERASER:
          if (attrs.value.isPaint) {
            var newPoints = state.eraserLine.points().concat([offsetX, offsetY])
            state.eraserLine.points(newPoints)
            state.konvaLayer.batchDraw()
          }
          break
      }
    }
    const handleStageMousedown = (e) => {
      if (!konvaApp.editor.isDrawing || isCtrlDown.value) return
      let { offsetX, offsetY } = e.evt
      switch (drawMode.value) {
        case STAGE_OPERATION_TYPE_ENUM.DRAW_BROKEN_LINE:
          break
        case STAGE_OPERATION_TYPE_ENUM.DRAW_POLYGON:
          break
        case STAGE_OPERATION_TYPE_ENUM.DRAW_OBSTACLE:
          break
        case STAGE_OPERATION_TYPE_ENUM.CLEAN_OBSTACLE:
          attrs.value.isPaint = true
          state.eraserLine = new Konva.Line({
            name: 'eraser',
            stroke: attrs.value.eraserStroke,
            strokeWidth: attrs.value.eraserStrokeWidth,
            globalCompositeOperation: attrs.value.eraserGlobalCompositeOperation,
            points: [offsetX, offsetY],
            lineJoin: 'round'
          })
          attrs.value.eraserLineIdList.push(state.eraserLine._id)
          state.konvaLayer.add(state.eraserLine)
          break
        case STAGE_OPERATION_TYPE_ENUM.ERASER:
          attrs.value.isPaint = true
          state.eraserLine = new Konva.Line({
            name: 'eraser',
            stroke: attrs.value.eraserStroke,
            strokeWidth: attrs.value.eraserStrokeWidth,
            globalCompositeOperation: attrs.value.eraserGlobalCompositeOperation,
            points: [offsetX, offsetY],
            lineJoin: 'round'
          })
          attrs.value.eraserLineIdList.push(state.eraserLine._id)
          state.konvaLayer.add(state.eraserLine)
          break
      }
    }
    const handleStageMouseup = (e) => {
      if (!konvaApp.editor.isDrawing || isCtrlDown.value) return
      switch (drawMode.value) {
        case STAGE_OPERATION_TYPE_ENUM.DRAW_BROKEN_LINE:
          break
        case STAGE_OPERATION_TYPE_ENUM.DRAW_POLYGON:
          break
        case STAGE_OPERATION_TYPE_ENUM.DRAW_OBSTACLE:
          break
        case STAGE_OPERATION_TYPE_ENUM.CLEAN_OBSTACLE:
          attrs.value.isPaint = false
          state.eraserLine = null
          konvaApp.snapshot.recordSnapshot()

          break
        case STAGE_OPERATION_TYPE_ENUM.ERASER:
          attrs.value.isPaint = false
          state.eraserLine = null
          konvaApp.snapshot.recordSnapshot()

          break
      }
    }
    const checkSaveDraw = (showMessage = false) => {
      let pointLen = attrs.value.data.length / 2
      let needNumber = null
      let result = true
      switch (drawMode.value) {
        case STAGE_OPERATION_TYPE_ENUM.DRAW_BROKEN_LINE:
          pointLen = attrs.value.data.length / 2
          attrs.value.closed = false
          needNumber = 2
          break
        case STAGE_OPERATION_TYPE_ENUM.DRAW_POLYGON:
          attrs.value.data.push(attrs.value.data[0])
          attrs.value.data.push(attrs.value.data[1])
          attrs.value.closed = true
          state.konvaLine?.closed(attrs.value.closed)
          pointLen = attrs.value.data.length / 2
          needNumber = 4
          break
      }
      if (needNumber && pointLen < needNumber) {
        showMessage &&
          ElMessage({
            message: `保存失败,至少需要绘制${needNumber}个点`,
            type: 'warning'
          })
        result = false
      }
      return result
    }
    const saveDraw = (showMessage = false) => {
      let needCheckSave = [STAGE_OPERATION_TYPE_ENUM.DRAW_BROKEN_LINE, STAGE_OPERATION_TYPE_ENUM.DRAW_POLYGON]
      if (needCheckSave.includes(drawMode.value)) {
        if (checkSaveDraw(showMessage)) {
          let newComponent = calcComponentPositionAndSize()
          let component = deepCopy(customComponentData)
          component.id = generateID()
          let name = '区域-'
          let defaultStyle = {}

          if (drawMode.value === STAGE_OPERATION_TYPE_ENUM.DRAW_BROKEN_LINE) {
            component.attrs.featureType = FEATURE_TYPE_ENUM.FORBID_LINE
            name = '线-'
          } else if (drawMode.value === STAGE_OPERATION_TYPE_ENUM.DRAW_POLYGON) {
            component.attrs.featureType = FEATURE_TYPE_ENUM.FORBID_AREA
          }
          defaultStyle = getDefaultStyle(component.attrs.featureType)

          component.attrs.name = name + component.id.substr(0, 6)
          component.style = { ...component.style, ...newComponent.style }
          component.attrs = { ...component.attrs, ...newComponent.attrs, ...defaultStyle }

          konvaApp.editor.addComponent({ component })
          nextTick(() => {
            let index = konvaApp.editor.getComponentIndex(component)
            konvaApp.editor.setClickComponentStatus(true)
            konvaApp.editor.setCurComponent({
              component: konvaApp.editor.componentData[index],
              index
            })
            konvaApp.editor.toggleShowAttrListPanel(true)
          })
        }
      }
      konvaApp.editor.closeDrawMode()
      konvaApp.snapshot.recordSnapshot()

      nextTick(() => {
        state.eraserLine = null
        state.obstacleLine = null
      })
    }
    const generateDataURL = () => {
      let dataURL = state.konvaLayer.toDataURL({
        pixelRatio: 1 / editorScale.value // or other value you need
      })
      // downloadURI(dataURL, 'stage.png')
      element.value.attrs.imageData = dataURL
    }
    const reloadImage = () => {
      let dataURL = element.value.attrs.imageData
      if (dataURL) {
        base64ToImg(dataURL)
          .then((result) => {
            state.konvaImage.image(result)
            state.konvaImage.draw()
          })
          .catch((err) => {})
          .finally(() => {})
      } else {
        state.originImage && state.konvaImage.image(state.originImage)
      }
    }
    const calcComponentPositionAndSize = () => {
      let component = {
        attrs: {},
        style: {}
      }
      let xList = attrs.value.data.filter((v, index) => index % 2 === 0).sort((a, b) => a - b)
      let yList = attrs.value.data.filter((v, index) => index % 2 !== 0).sort((a, b) => a - b)
      let rectW = xList[xList.length - 1] - xList[0]
      let rectH = yList[xList.length - 1] - yList[0]
      component.style.width = rectW + MIN_RECT
      component.style.height = rectH + MIN_RECT
      component.style.top = yList[0] + element.value.style.top - MIN_RECT / 2
      component.style.left = xList[0] + element.value.style.left - MIN_RECT / 2
      component.style.opacity = state.newComponentOpacity
      let list = attrs.value.data.map((point, index) => {
        if (index % 2 === 0) {
          return point - xList[0] + MIN_RECT / 2
        } else {
          return point - yList[0] + MIN_RECT / 2
        }
      })
      component.attrs = { ...attrs.value, data: list }
      attrs.value.data = []
      return component
    }
    const calcComponentPositionAndSizeForPoint = (points, opacity) => {
      let scale = konvaApp.editor.canvasStyleData.scale
      let component = {
        attrs: {},
        style: {}
      }
      let xList = points.filter((v, index) => index % 2 === 0).sort((a, b) => a - b)
      let yList = points.filter((v, index) => index % 2 !== 0).sort((a, b) => a - b)
      component.style.width = (MIN_RECT * 2 * scale) / 100
      component.style.height = (MIN_RECT * 2 * scale) / 100
      component.style.top = yList[0] - component.style.width / 2
      component.style.left = xList[0] - component.style.height / 2
      component.style.opacity = opacity
      let list = points.map((point, index) => {
        if (index % 2 === 0) {
          return point - component.style.left
        } else {
          return point - component.style.top
        }
      })
      component.attrs = { data: list }
      return component
    }
    /**
     * @description: 隐藏一般组件
     * @return {*}
     */
    const hideNormalComponent = () => {
      if (!state.showNormal) return
      state.showNormalComponentId = []
      normalComponentData.value.forEach((component) => {
        if (component.visible) {
          state.showNormalComponentId.push(component.id)
          component.visible = false
        }
      })
      ElMessage({
        message: '标注已隐藏',
        type: 'info'
      })
      state.showNormal = false
    }
    /**
     * @description: 展示一般组件
     * @return {*}
     */
    const showNormalComponent = () => {
      if (state.showNormal) return

      normalComponentData.value.forEach((component) => {
        if (state.showNormalComponentId.includes(component.id)) {
          component.visible = true
        }
      })
      ElMessage({
        message: '标注已恢复显示',
        type: 'info'
      })
      state.showNormal = true
    }
    /**
     * @description: 初始化时计算底图位置和大小
     * @param {*} width 图片宽度
     * @param {*} height 图片高度
     * @return {*}
     */
    const calcStagePositionAndSize = (width, height) => {
      emitter.emit('getEditorBoxStyle')
      let result = {}
      let offsetScale = 0.01 //缩放偏差与容器留一点空白
      let scaleX = konvaApp.editor.editorBoxStyle.width / width
      let scaleY = konvaApp.editor.editorBoxStyle.height / height
      let scale = Math.min(scaleX, scaleY) - offsetScale
      result.scale = Math.max(scale, konvaApp.editor.limitScale.min / 100)
      result.height = height * scale
      result.width = width * scale
      result.top = (konvaApp.editor.editorBoxStyle.height - result.height) / 2
      result.top = result.top < 0 ? 0 : result.top
      result.left = (konvaApp.editor.editorBoxStyle.width - result.width) / 2
      result.left = result.left < 0 ? 0 : result.left
      return result
    }
    /**
     * @description: 校正画布
     * @return {*}
     */
    const correctStage = () => {
      let { width, height } = state.originImage
      let style = element.value.style
      let result = calcStagePositionAndSize(width, height)
      //先计算缩放
      emitter.emit('setScale', result.scale * 100)
      //再计算位移
      style.left = result.left
      style.top = result.top
    }
    const addEvent = () => {
      state.konvaStage.on('click', handleStageClick)
      state.konvaStage.on('mousemove', handleStageMousemove)
      state.konvaStage.on('mousedown', handleStageMousedown)
      state.konvaStage.on('mouseup', handleStageMouseup)
      emitter.on('generateDataURL', generateDataURL)
      emitter.on('overOperation', overOperation)
      emitter.on('correctStage', correctStage)
      emitter.on('calcStageOffsetForScale', calcStageOffsetForScale)
    }
    const removeEvent = () => {
      if (!state.konvaStage) return
      state.konvaStage.off('click', handleStageClick)
      state.konvaStage.off('mousemove', handleStageMousemove)
      state.konvaStage.off('mousedown', handleStageMousedown)
      state.konvaStage.off('mouseup', handleStageMouseup)

      emitter.off('generateDataURL')
      emitter.off('overOperation')
      emitter.off('correctStage')
      emitter.off('calcStageOffsetForScale', calcStageOffsetForScale)
    }

    onMounted(() => {
      getMapInfo()
    })
    onBeforeUnmount(() => {
      removeEvent()
    })
    onUnmounted(() => {
      konvaApp.stage.setStage(null)
      state.extensionVisible.range = false
      // state.konvaStage?.destroy()
    })
    return {
      ...toRefs(state),
      handleOperationOver
    }
  }
}
</script>

<style lang="scss" scoped>
#konvaStage {
  // border: #aaa solid 1px;
}
</style>
