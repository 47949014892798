
let config = {
    "Version": "3.9.4",
    "Title": "PureAdmin",
    "FixedHeader": true,
    "HiddenSideBar": false,
    "MultiTagsCache": false,
    "KeepAlive": true,
    "Locale": "zh",
    "Layout": "vertical",
    "Theme": "default",
    "DarkMode": false,
    "Grey": false,
    "Weak": false,
    "HideTabs": false,
    "SidebarStatus": true,
    "EpThemeColor": "#409EFF",
    "ShowLogo": true,
    "ShowModel": "smart",
    "MenuArrowIconNoTransition": true,
    "CachingAsyncRoutes": true,
    "TooltipEffect": "light",
}


const setConfig = (cfg) => {
    config = Object.assign(config, cfg);
};

const getConfig = (key) => {
    if (typeof key === "string") {
        const arr = key.split(".");
        if (arr && arr.length) {
            let data = config;
            arr.forEach(v => {
                if (data && typeof data[v] !== "undefined") {
                    data = data[v];
                } else {
                    data = null;
                }
            });
            return data;
        }
    }
    return config;
};

/** 获取项目动态全局配置 */
export const getServerConfig = (app) => {
    app.config.globalProperties.$config = getConfig();
    let $config = app.config.globalProperties.$config;
    // 自动注入项目配置
    if (app && $config && typeof config === "object") {
        $config = Object.assign($config, config);
        app.config.globalProperties.$config = $config;
        // 设置全局配置
        setConfig($config);
    }
    return $config;
};

export { getConfig, setConfig };
