import elementResizeDetectorMaker from "element-resize-detector";
import { emitter } from "@/utils/mitt";

const erd = elementResizeDetectorMaker({
  strategy: "scroll"
});

const resize = {
  mounted(el, binding, vnode) {
    erd.listenTo(el, elem => {
      const width = elem.offsetWidth;
      const height = elem.offsetHeight;
      if (binding?.instance) {
        emitter.emit("resize", { detail: { width, height } });
      } else {
        vnode.el.dispatchEvent(
          new CustomEvent("resize", { detail: { width, height } })
        );
      }
    });
  },
  unmounted(el) {
    erd.uninstall(el);
  }
};
export default resize