/*
 * @Author: fangjun
 * @Date: 2023-03-10 11:03:56
 * @LastEditors: fangjun
 * @Description: 
 * @LastEditTime: 2023-03-10 16:09:07
 * @FilePath: \src\konva\index.js
 */
export default {
  install(app) {
    const components = import.meta.globEager(['/src/konva/components/*.vue'])
    let konvaComponents = {}
    for (let key in components) {
      let name = key.substring(key.lastIndexOf('/') + 1, key.lastIndexOf('.'))
      name = name.charAt(0).toUpperCase() + name.slice(1)
      konvaComponents[name] = components[key].default
      app.component(name, konvaComponents[name])
    }
  }
}
