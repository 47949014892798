/*
 * @Author: fangjun
 * @Date: 2023-01-30 08:45:20
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2023-03-15 11:01:36
 * @FilePath: \src\main.js
 */
import { createApp } from 'vue'
import App from './App.vue'
import directive from '@/directives'
import pinia from '@/store'
import router from '@/router'
import './index.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import { getServerConfig } from '@/config'
import { injectResponsiveStorage } from '@/utils/responsive'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import '@/permission' // permission control
// 引入重置样式
import './style/reset.scss'
// 导入公共样式
import './style/index.scss'
import 'element-plus/dist/index.css'
import other from '@/utils/other'
import konvaComponents from '@/konva/index.js' // 注册自定义组件

const app = createApp(App)
app.config.globalProperties.$app = app
const config = getServerConfig(app)
injectResponsiveStorage(app, config)
directive(app)
// 注册icon组件
other.elSvg(app)

app.use(pinia).use(router).use(ElementPlus, { locale: zhCn }).use(konvaComponents).mount('#app')
