<!--
 * @Author: fangjun
 * @Date: 2023-03-20 09:42:55
 * @LastEditors: fangjun
 * @Description: 
 * @LastEditTime: 2024-07-11 11:15:20
 * @FilePath: \src\konva\components\konvaLine.vue
-->

<template>
  <div>
    <canvas ref="container" :width="element.style.width" :height="element.style.height"></canvas>
    <div class="name-box" :style="textStyle" :title="element.attrs.name">
      <div class="name">{{ element.attrs.name }}</div>
      <div class="name" v-show="typeName">【{{ typeName }}】</div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, reactive, toRefs, getCurrentInstance, watch, nextTick } from 'vue'
import { getCirclePoint } from '@/utils/konvaEditor/translate'
import { featureTypeOptions } from '@/store/konvaEditor/index.js'
import { MIN_RECT } from '@/utils/konvaEditor/style'

export default {
  name: 'konvaCustomLine',
  props: {
    propValue: {
      type: String,
      require: true
    },
    element: {
      type: Object
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const state = reactive({
      ctx: null
    })
    const { element } = toRefs(props)

    const attrs = computed(() => {
      return JSON.parse(JSON.stringify(element.value.attrs))
    })
    const elementStyle = computed(() => {
      return JSON.parse(JSON.stringify(element.value.style))
    })
    const typeName = computed(() => {
      return featureTypeOptions.find((type) => attrs.value.featureType === type.value)?.label ?? null
    })
    const defaultStyle = computed(() => {
      return featureTypeOptions.find((type) => attrs.value.featureType === type.value)?.style ?? {}
    })
    const textStyle = computed(() => {
      let { color, fontSize } = attrs.value
      let rotate = -elementStyle.value.rotate
      return {
        color,
        'font-size': fontSize + 'px',
        transform: 'rotate(' + rotate + 'deg) translate(-50%, -50%)'
      }
    })
    watch(
      () => attrs.value,
      (newV, oldV) => {
        if (oldV.fill !== newV.fill) {
          nextTick(() => {
            drawDiagram()
          })
        }
        if (element.value.type === 'polygon') {
          if (oldV.pointNumber !== newV.pointNumber) {
            calcCircleAttrsData()
            nextTick(() => {
              drawDiagram()
            })
          }
        }
        if (oldV.featureType !== newV.featureType) {
          element.value.attrs = { ...element.value.attrs, ...defaultStyle.value }
        }
      },
      {
        deep: true
      }
    )
    watch(
      () => elementStyle.value,
      (value, old) => {
        let { width, height, opacity } = value
        let oldWidth = old.width,
          oldHeight = old.height
        if (opacity !== old.opacity && state.ctx) {
          nextTick(() => {
            drawDiagram()
          })
        }
        if (!width || !height) {
        } else {
          if (width !== oldWidth || height !== oldHeight) {
            if (element.value.type === 'polygon') {
              calcCircleAttrsData()
              nextTick(() => {
                drawDiagram()
              })
            } else {
              if (!element.value.attrs.originStyle) {
                element.value.attrs.originStyle = {
                  width: element.value.style.width,
                  height: element.value.style.height
                }
              }

              //缩放之后重新计算所有点的位置
              element.value.attrs.data = element.value.attrs.data.map((point, index) => {
                if (index % 2 === 0) {
                  return (width / element.value.attrs.originStyle.width) * point
                } else {
                  return (height / element.value.attrs.originStyle.height) * point
                }
              })
              element.value.attrs.originStyle.width = width
              element.value.attrs.originStyle.height = height
              nextTick(() => {
                drawDiagram()
              })
            }
          }
        }
      },
      { deep: true }
    )
    const init = () => {
      state.ctx = proxy.$refs['container'].getContext('2d')
      if (element.value.type === 'polygon') {
        calcCircleAttrsData()
      }
      element.value.attrs.originStyle = {
        width: element.value.style.width,
        height: element.value.style.height
      }
      drawDiagram()
    }
    /**
     * @description: 计算组成圆的点
     * @return {*}
     */
    const calcCircleAttrsData = () => {
      // if (element.value.attrs.data.length > 0) return
      let pointNumber = attrs.value.pointNumber
      let { width, height } = element.value.style
      let radiusX = width / 2 - MIN_RECT / 2
      let radiusY = height / 2 - MIN_RECT / 2
      let interval = 360 / pointNumber
      element.value.attrs.data = getCirclePoint(radiusX, radiusY, interval, radiusX, radiusY)
      element.value.attrs.data = element.value.attrs.data.map((point) => (point += MIN_RECT / 2))
    }
    //绘制线和多边形
    const drawDiagram = () => {
      let ctx = state.ctx,
        attrs = element.value.attrs
      if (!ctx) return
      ctx.clearRect(0, 0, element.value.style.width, element.value.style.height)
      ctx.beginPath()
      ctx.globalAlpha = element.value.style.opacity
      ctx.strokeStyle = attrs.stroke
      ctx.lineWidth = attrs.strokeWidth
      let pointList = element.value.attrs.data
      for (let i = 0; i < pointList.length - 1; i += 2) {
        let x = pointList[i],
          y = pointList[i + 1]
        if (i == 0) {
          ctx.moveTo(x, y)
        } else {
          ctx.lineTo(x, y)
        }
      }
      if (attrs.closed) {
        ctx.closePath()
        ctx.stroke()
        ctx.fillStyle = attrs.fill
        ctx.fill()
      } else {
        ctx.stroke()
      }
    }
    onMounted(() => {
      init()
    })
    onUnmounted(() => {})
    return {
      ...toRefs(state),
      textStyle,
      typeName
    }
  }
}
</script>
<style lang="scss" scoped>
.name-box {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  background-color: #ffffff80;
  border-radius: 5px;
  transform-origin: 0 0;
  .name {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    min-width: 80px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>
