/*
 * @Author: fangjun
 * @Date: 2023-01-30 08:37:33
 * @LastEditors: fangjun
 * @Description:
 * @LastEditTime: 2023-08-07 11:15:34
 * @FilePath: \src\directives\index.js
 */
import resize from './resize'
import debounce from './debounce'
import hasPermi from './hasPermi'
import blur from './blur'
import track from './track'

const directives = {
  resize,
  debounce,
  hasPermi,
  blur,
  track
}

export default function (app) {
  for (let key in directives) {
    app.directive(key, directives[key])
  }
}
