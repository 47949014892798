import { sendTrackInfo } from '@/api/common'
import { debounce } from '@/utils'

export default {
  mounted(el, binding) {
    let [btnName] = binding.value
    let obj = {
      eventType: '2',
      btnName
    }
    el.addEventListener(
      'click',
      debounce(
        () => {
          sendTrackInfo(obj)
        },
        300,
        false
      )
    )
  }
}
